const partnerForms = document.querySelectorAll(".contact-form-wrapper");
const formButtons = document.querySelectorAll(".partner-button");
const body = document.querySelector("body");

const hasMultipleForms = partnerForms.length > 1;

formButtons.forEach(button => {
	button.addEventListener("click", function(e) {
		let partnerForm;

		if (hasMultipleForms) {
			// First try to find form in the same section
			const section = button.closest('.contact-form');
			if (section) {
				partnerForm = section.querySelector('.contact-form-wrapper');
			} else {
				// If button is outside contact-form section, find the form with show-block class
				partnerForm = document.querySelector(".contact-form-wrapper.show-block");
			}
		} else {
			partnerForm = document.querySelector(".contact-form-wrapper");
		}

		if (partnerForm) {
			const formContainer = partnerForm.querySelector(".contact-form-container");

			partnerForm.classList.add("display");
			setTimeout(function() {
				partnerForm.classList.add("active");
				formContainer.classList.add("active");
				body.style.overflow = "hidden";
			}, 1);
		}
	});
});

// Handle exit events
partnerForms.forEach(form => {
	const exitElements = form.querySelectorAll(".backdrop, .form-exit");
	const formContainer = form.querySelector(".contact-form-container");

	exitElements.forEach(element => {
		element.addEventListener("click", function(e) {
			body.style.overflow = "visible";
			form.classList.remove("active");
			formContainer.classList.remove("active");
			setTimeout(function() {
				form.classList.remove("display");
			}, 300);
		});
	});
});
const contactForm = document.querySelector(".contact-form-wrapper");
if (contactForm) {
	const contactFormSection = document.querySelector(".contact-form");
	const formButton = document.querySelectorAll(".partner-button");
	const formContainer = contactForm.querySelector(".contact-form-container");
	const exit = contactForm.querySelectorAll(".backdrop, .form-exit");
	const body = document.querySelector("body");
	formButton.forEach((button) => {
		button.addEventListener("click", function (e) {
			contactForm.classList.add("display");
			setTimeout(function () {
				contactForm.classList.add("active");
				formContainer.classList.add("active");
				body.style.overflow = "hidden";
			}, 1);
		});
	});

	exit.forEach((element) => {
		element.addEventListener("click", function (e) {
			body.style.overflow = "visible";
			contactForm.classList.remove("active");
			formContainer.classList.remove("active");
			setTimeout(function () {
				contactForm.classList.remove("display");
			}, 300);
		});
	});
}
